



















import { ConjunctionOperator, FilterOperator } from '@movecloser/front-core'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import {
  AbstractCheckControlProps
} from '../../../../dsl/abstract/components/CheckControl/CheckControl.contracts'
import { FilterOptions } from '../../../../contexts'

import { FilterListProps } from './FilterList.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<FilterList>({
  name: 'FilterList'
})
export class FilterList extends Vue {
  @Prop({ required: true, type: Object })
  public items!: FilterOptions

  @Prop({ required: false, type: Boolean, default: false })
  public isMulti!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isOpen!: boolean

  @Prop({ required: false, type: String })
  public listClass?: string

  @Prop({ required: true, type: String })
  public queryParam!: string

  @Prop({ required: false, type: Boolean, default: false })
  public sort!: boolean

  @PropSync('filter', { required: true, default: null })
  public _filter!: FilterListProps['filter']

  public get listItems () {
    let itemsArray = Object.entries(this.items).map(([value, label]) => ({ value, label }))

    if (this.sort) {
      itemsArray = itemsArray.sort((a, b) => a.label > b.label ? 1 : -1)
    }

    return itemsArray
  }

  public get selected (): AbstractCheckControlProps<string | string[]>['model'] {
    if (!this.isMulti) {
      if (Array.isArray(this._filter)) {
        throw new Error('Non-multi filter cannot be instantiated with an array')
      }

      return this._filter?.toString() ?? ''
    }

    if (!this._filter) {
      return []
    }

    if (!Array.isArray(this._filter)) {
      throw new Error('Multi filter cannot be instantiated with a single value')
    }

    return this._filter.map(f => f.value.toString())
  }

  public set selected (value: string | string[]) {
    if (!this.isMulti) {
      if (Array.isArray(value)) {
        throw new Error('Non-multi filter cannot return an array')
      }

      this._filter = value
      return
    }

    if (!Array.isArray(value)) {
      throw new Error('Multi filter cannot return a single value')
    }

    this._filter = value.map((v, i) => ({
      operator: FilterOperator.Equal,
      value: v,
      ...(i !== 0 ? { conjunction: ConjunctionOperator.Or } : {})
    }))
  }
}

export default FilterList
