
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FILTER_BADGES_COMPONENT_KEY = 'FilterBadges'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface FilterBadgesConfig {
  closeIcon: string
  hasClearAllIcon: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FILTER_BADGES_COMPONENT_CONFIG_MAP: FilterBadgesConfig = {
  closeIcon: 'CloseIcon',
  hasClearAllIcon: false
}
