export const FILTERS_DRAWER_COMPONENT_KEY = 'FiltersDrawer'

export enum FiltersDrawerMode {
  Default = 'default',
  InstantApply = 'instant-apply',
  InstantApplyAndClose = 'instant-close'
}

export const FILTERS_DRAWER_COMPONENT_CONFIG_MAP = {
  hasCategoryTitle: false,
  mode: FiltersDrawerMode.Default,
  shouldRenderEmptyState: true
}
