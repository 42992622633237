import { AnyObject } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const PRODUCT_REVIEW_COMPONENT = 'ProductReviewComponent'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultConfig: AnyObject = {
  shouldShowDescription: true
}
