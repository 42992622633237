

import { Component, Prop, Vue } from 'vue-property-decorator'
import { ModalPayload as Abstract } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface ModalPayload extends Abstract {
  onClose?: () => void
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'AbstractModal' })
export class AbstractModal<P extends ModalPayload = ModalPayload> extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public readonly closable!: boolean

  @Prop({ type: Function, required: true })
  public readonly closeCb!: () => void

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly payload!: P

  /**
   * Determines whether the payload has been injected.
   */
  public get hasPayload (): boolean {
    return typeof this.payload !== 'undefined' && Object.keys(this.payload).length > 0
  }

  /**
   * Closes the modal.
   */
  public close (): void {
    if (this.hasPayload && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
      return
    }

    this.closeCb()
  }
}

export default AbstractModal
