
















































import { Component, Mixins, Prop } from 'vue-property-decorator'

import { StructureConfigurable } from '../../../../support/mixins'

import CircleCheckIcon from './partials/CircleCheckIcon.vue'
import ShopCartSimpleIcon from './partials/ShopCartSimpleIcon.vue'
import { ProductReviewProps } from './ProductReview.contracts'
import { defaultConfig, PRODUCT_REVIEW_COMPONENT } from '../ProductReview.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductReview>({
  name: 'ProductReview',
  components: {
    CircleCheckIcon,
    ShopCartSimpleIcon
  },
  created (): void {
    this.config = this.getComponentConfig(PRODUCT_REVIEW_COMPONENT, defaultConfig)
  }
})
export class ProductReview extends Mixins(StructureConfigurable) implements ProductReviewProps {
  @Prop({ type: String, required: true, default: '' })
  public author!: ProductReviewProps['author']

  @Prop({ type: String, required: false, default: '' })
  public color!: ProductReviewProps['color']

  @Prop({ type: String, required: false, default: '' })
  public content!: ProductReviewProps['content']

  @Prop({ type: String, required: true, default: '' })
  public createdAt!: ProductReviewProps['createdAt']

  @Prop({ type: String, required: true, default: '' })
  public desc!: ProductReviewProps['desc']

  @Prop({ type: Boolean, required: true, default: false })
  public isVerified!: ProductReviewProps['isVerified']

  @Prop({ type: String, required: true, default: '/' })
  public location!: ProductReviewProps['location']

  @Prop({ type: Number, required: true, default: 5 })
  rate!: ProductReviewProps['rate']

  @Prop({ type: String, required: true, default: '' })
  title!: ProductReviewProps['title']

  @Prop({ type: Number, required: false, default: 0 })
  type!: ProductReviewProps['type']

  /**
   * Formats to DD.MM.YYYY from createdAt string 'YYYY-MM-DD ..."
   */
  public get formatterCreatedAt () {
    return `${this.createdAt.slice(8, 10)}.${this.createdAt.slice(5, 7)}.${this.createdAt.slice(0, 4)}`
  }

  /**
   * Determines whether description is displayed.
   */
  public get shouldShowDescription (): boolean {
    return this.getConfigProperty<boolean>('shouldShowDescription')
  }
}

export default ProductReview
