



















































import { Component } from 'vue-property-decorator'

import { Inject, logger } from '../../../../support'
import { ProductReviewData } from '../../../../contexts'

import { AbstractModal } from '../../../shared/organisms/AbstractModal'
import { Loader } from '../../../shared/molecules/Loader'

import {
  IProductReviewsRepository,
  ReviewsRepositoryType
} from '../../contracts/repositories'
import { ProductReview } from '../../molecules/ProductReview'

import { REVIEWS_MAX_SHOW_LENGTH } from './ProductReviewsModal.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductReviewsModal>({
  name: 'ProductReviewsModal',
  components: { Loader, ProductReview },
  created () {
    this.fetchProductReviewsList()
  }
})
export class ProductReviewsModal extends AbstractModal {
  @Inject(ReviewsRepositoryType)
  protected readonly reviewsRepository!: IProductReviewsRepository

  /**
   * Average variant rate from all reviews
   */
  public averageRate!: number

  /**
   * Are reviews being fetched
   */
  public isLoading: boolean = false

  /**
   * Error message
   */
  public error: string | null = null

  /**
   * Array with reviews objects
   */
  public reviews!: ProductReviewData[] | null

  /**
   * Number of reviews shown to the user.
   * If user will be redirected to the product page if he wants to see more of them.
   */
  public reviewsToShow: number = REVIEWS_MAX_SHOW_LENGTH

  /**
   * Get all reviews that will be displayed to the user
   */
  public get getShownReviews () {
    if (this.reviews) {
      if (this.reviews.length < this.reviewsToShow) {
        return this.reviews.slice(0, this.reviews.length)
      } else {
        return this.reviews.slice(0, this.reviewsToShow)
      }
    }
  }

  /**
   * Get / set reviews data from computed
   */
  public get productReviews (): ProductReviewData[] | null {
    return this.reviews
  }

  public set productReviews (payload: ProductReviewData[] | null) {
    this.reviews = payload
  }

  /**
   * Return calculated average rating
   */
  public get averageRateValue (): number {
    return this.averageRate
  }

  public set averageRateValue (value: number) {
    this.averageRate = value
  }

  /**
   * Fetch product reviews list
   * @protected
   */
  protected async fetchProductReviewsList () {
    this.isLoading = true

    try {
      this.productReviews = await this.reviewsRepository.loadProductReviews(this.payload.sku)

      /**
       * Calculate rates sum, then...
       * calculate average rating
       */
      let sum = 0
      for (const value of Object.values(this.productReviews)) {
        sum += value.rate
      }

      this.averageRateValue = Number((sum / this.productReviews.length).toFixed(2))
    } catch (e) {
      this.productReviews = null
      this.error = (e as Error).message

      /**
       * Log error
       */
      logger((e as Error), 'error')
    }

    this.isLoading = false
  }
}

export default ProductReviewsModal
